.schedule-container {
	// background-color: rgba(239, 244, 245, 0.705);
	height: 110%;
	// padding-top: 40px;
	// padding-bottom: 10%;
	background-color: #ffffff;
	height: 1300px;
	font-family: Georgia, "Times New Roman", Times, serif;
	margin-bottom: 120px;
}

.selected {
	background: rgb(223, 228, 218);
	padding: 0 2px;
}

.unselected {
	padding: 0 2px;
	background-color: rgb(235, 194, 194);
}

.new-cambridge {
	font-size: 18px;
	font-weight: 700;
	background-color: rgb(245, 245, 115);
	width: 7%;
	text-align: center;
	padding: 5px;
	border-radius: 7px;
	color: black;
}

.service-description {
	font-size: 16px;
	font-weight: 700;
	color: rgb(52, 78, 151);
}

.booking-selection-container {
	background-color: #ffffff;
	padding: 10px;
	border-radius: 10px;
	// margin: 10px;
}

.service-dog-container {
	padding: 10px;
	margin: 5px;
	cursor: pointer;
	border-radius: 10px;

	&:hover {
		filter: brightness(1.4);
	}

	&__bath {
		border: 1px rgb(255, 255, 255) solid;
		background-color: rgba(67, 211, 180, 0.548);

		&__active {
			border: solid black 2px;
			box-shadow: 3px 5px 5px rgb(170, 170, 170);
		}
	}

	&__grooming {
		border: 1px rgb(255, 255, 255) solid;
		background-color: rgba(63, 177, 253, 0.548);

		&__active {
			border: solid black 2px;
			box-shadow: 3px 5px 5px rgb(156, 156, 156);
		}
	}
}

.cityChosen {
	margin: 10px auto;
	font-size: 26px;
	text-shadow: 1px 1px rgb(72, 72, 253);
}

.cambridge-btn,
.cambridge-btn:focus,
.milton-btn,
.milton-btn:focus {
	outline: 0;
	font-size: 50px;
	padding: 100px;
	border-radius: 10px;
	font-weight: 700;
	border: 4px solid rgb(0, 0, 0);
	background-color: rgb(255, 255, 255);
	color: rgb(0, 0, 0);
	width: 47%;
	margin: 10px;
	transition: all 0.2s;
	text-shadow: 5px 5px 15px rgb(67, 99, 134);
}

.cambridge-btn:hover,
.milton-btn:hover {
	transform: scale(1.02);
	box-shadow: 0px 10px 10px black;
	filter: brightness(1.2);
}

.new-cambridge-btn {
	position: absolute;
	right: 80px;
	top: 200px;
	transform: rotate(20deg);
	font-size: 40px;
	color: yellow;
}

.reset-all_reset_city_buttons_container {
	top: 80px;
	left: 20px;
	position: absolute;
}

.change-city-btn {
	padding: 5px 10px;
	border-radius: 5px;
	background-color: rgb(73, 150, 163);
	color: white;
	border: none;
}

.reset-btn-schedule {
	padding: 5px 10px;
	border: none;
	margin-left: 30px;
	background-color: rgb(243, 135, 135);
	color: white;
	border-radius: 5px;
}

.pet_selection_service_groomer_container {
	border: 1px solid rgb(235, 232, 232);
	width: 80%;
	padding: 20px;
	margin: 0 auto;
	border-radius: 3px;
	box-shadow: 2px 2px 10px rgb(224, 221, 221);
}

.bookingBtnHome {
	margin-top: 350px;
}

.text-center {
	text-align: center;
}

.err-msg-schedule {
	text-align: center;
	margin-top: 10px;
	color: red;
}

.first-time-client-wrapper {
	margin: 20px auto;
	text-align: center;
	font-size: 17px;
}

.groomers-profile-pics__parent-active {
	transform: scale(1.1);
	border-radius: 40px;
}
.groomers-profile-pics__wrapper {
	width: 40%;
	margin: 20px auto;
	display: grid;
	grid-template-columns: repeat(4, 1fr);

	.groomers-profile-pics__parent {
		.groomers-profile-pics {
			margin: 10px;
			border-radius: 70px;
			border: 5px solid rgb(32, 110, 184);
			transition: all 0.2s;
			cursor: pointer;

			&:hover {
				transform: scale(1.1);
				box-shadow: 0px 10px 50px black;
			}
		}
	}
}

.img_groomer-active {
	border: rgb(0, 230, 122) 5px solid !important;
	margin: 10px;
	border-radius: 70px;
}

.service-info {
	display: none;
}

.select-groomer-btn {
	font-size: 15px;
	margin-left: 15px;
	border-radius: 8px;
	background-color: rgb(65, 182, 65);
	color: white;
}

.bather-profile-pics__wrapper {
	width: 15%;
	margin: 20px auto;

	.bather-profile-pics {
		width: 60%;
		margin: 20px auto;
		border-radius: 70px;
		border: 5px solid rgb(32, 110, 184);
		transition: all 0.2s;
		cursor: pointer;

		&:hover {
			transform: scale(1.1);
			box-shadow: 0px 10px 50px black;
		}
	}
}

.read-more-groomers-btn:active,
.read-more-groomers-btn {
	padding: 5px 20px;
	border-radius: 5px;
	background-color: rgb(224, 188, 28);
	color: rgb(0, 0, 0);
	font-weight: 700;
	border: none;
	outline: none !important;
	box-shadow: none !important;
}

.schedule-primary-heading {
	color: rgb(29, 7, 16);
	margin-top: 120px;
	font-size: 26px;
}

.nail-trim-service {
	background-color: rgb(250, 226, 6);
	width: 50%;
	margin: 10px auto;
	font-size: 18px;
	font-weight: 700;
	padding: 10px;
	border-radius: 10px;
	border: 1px solid rgb(19, 46, 77);
	color: rgb(124, 75, 1);
}

.groomers-profile-pics__cat {
	margin: 10px;
	border-radius: 70px;
	border: 5px solid rgb(32, 110, 184);
	transition: all 0.2s;
	cursor: pointer;

	&:hover {
		transform: scale(1.1);
		box-shadow: 0px 10px 50px black;
	}
}

.groomers-profile-pics__calendar {
	width: 6%;
	margin: 5px;
	border-radius: 70px;
	border: 1px solid rgb(32, 110, 184);
	transition: all 0.2s;
}

.checkbox-appointment {
	transform: scale(2);
	margin: 0 10px;
}

.modal-schedule {
	height: 90vh;
}

.spam-folder-msg {
	font-size: 14px;
	text-decoration: underline;
}

.modal-success-container {
	background-color: rgb(0, 2, 5);
	padding: 10px;
	color: white;
	border-radius: 10px;
}

.success-heading-modal {
	color: #ffffff;
	text-shadow: 0 0 10px #ffffff;
}

.modal-success-close-btn {
	border-radius: 5px;
	padding: 5px 30px;
	font-size: 16px;
	background-color: rgb(196, 230, 49);
	color: rgb(0, 0, 0);
	font-weight: 700;
	box-shadow: 0px 10px 50px white;
}

.fa-square {
	font-size: 22px;
	color: rgb(19, 235, 55);
}

.modal-instructions {
	color: rgb(84, 107, 21);
	font-weight: 700;
	font-size: 15px;
	text-shadow: -7px 0px 8px rgba(177, 250, 129, 1);
}

.continue-schedule-btn {
	padding: 10px 30px;
	margin: 30px auto 200px auto;
	background-color: rgb(160, 243, 164);
	color: rgb(0, 0, 0);
	border-radius: 5px;
	display: block;
	width: 100%;
	transition: all 0.2s;
	border: none;
	backface-visibility: hidden;

	&:hover {
		transform: scale(1.02);
		box-shadow: 0px 10px 10px rgb(143, 143, 143);
		border: 0.5px solid rgb(76, 204, 76);
	}
}

.city_container {
	margin-top: 0;
	font-family: Georgia, "Times New Roman", Times, serif !important;
}

.petType-wrapper {
	display: grid;
	grid-template-columns: 1fr 1fr;
	justify-items: center;

	.choose-title {
		grid-column: 1 / 3;
	}

	.figCaption-pets {
		margin-top: 20px;
		font-weight: 700;
	}

	.petTypeImg-wrapper {
		.pet-type-img {
			transition: all 0.2s;
			cursor: pointer;
			width: 20%;

			&__dog {
				&:hover {
					transform: scale(1.2);
				}
			}

			&__cat {
				&:hover {
					transform: scale(1.2);
				}
			}
		}
	}
}

@media only screen and (min-device-width: 200px) and (max-width: 1500px) {
	.schedule-container {
		height: 100%;
	}
}

@media only screen and (min-device-width: 1200px) and (max-width: 1500px) {
	.cambridge-btn,
	.milton-btn {
		font-size: 80px;
	}
	.change-city-btn {
		left: 2%;
		top: 80px;
	}
}

@media only screen and (min-device-width: 992px) and (max-width: 1199px) {
	.cambridge-btn,
	.milton-btn {
		font-size: 60px;
		padding: 80px;
	}

	.change-city-btn {
		left: 2%;
		// top: -30px;
	}

	.new-cambridge-btn {
		font-size: 30px;
	}
}

@media only screen and (min-device-width: 600px) and (max-width: 991px) {
	.cambridge-btn,
	.milton-btn {
		font-size: 40px;
		padding: 50px 10px;
	}

	.change-city-btn {
		left: 2%;
		// top: -30px;
	}

	.new-cambridge-btn {
		font-size: 25px;
		right: 40px;
	}
}

@media only screen and (min-device-width: 600px) and (max-width: 666px) {
	.new-cambridge-btn {
		font-size: 25px;
		right: 180px;
		top: 400px;
	}
}

@media only screen and (min-device-width: 500px) and (max-width: 599px) {
	.schedule-primary-heading {
		font-size: 32px;
	}

	.new-cambridge-btn {
		font-size: 25px;
		right: 60px;
		top: 360px;
	}
}

@media only screen and (min-device-width: 420px) and (max-width: 499px) {
	.schedule-primary-heading {
		font-size: 28px;
	}

	.new-cambridge-btn {
		font-size: 25px;
		right: 60px;
		top: 340px;
	}
}

@media only screen and (min-device-width: 200px) and (max-width: 436px) {
	.new-cambridge-btn {
		font-size: 25px;
		right: 60px;
		top: 340px;
	}
	.reset-btn-schedule {
		// padding: 2px;
		width: 100%;
		display: block;
		margin: 2px 0 0 0;
	}

	.change-city-btn {
		width: 100%;
		display: block;
	}
	.schedule-primary-heading {
		font-size: 22px;
		margin-top: 140px;
	}

	.reset-all_reset_city_buttons_container {
		left: 0;
		top: 65px;
		display: grid;
		text-align: center;
		width: 100%;
	}
}
@media only screen and (min-device-width: 437px) and (max-width: 599px) {
	.new-cambridge-btn {
		font-size: 25px;
		right: 60px;
		top: 340px;
	}

	.schedule-primary-heading {
		font-size: 22px;
		margin-top: 100px;
	}
	.reset-btn-schedule {
		padding: 2px;
		width: 50%;
	}

	.change-city-btn {
		width: 50%;
	}

	.reset-all_reset_city_buttons_container {
		padding: 0 5px;
		left: 0;
		top: 65px;
		display: flex;
		justify-content: space-evenly;
		width: 100%;
	}
}

@media only screen and (min-device-width: 200px) and (max-width: 599px) {
	.service_selection_text {
		font-size: 15px;
	}
	.pet-type-img {
		width: 60px !important;
	}

	.modal-schedule {
		height: 100%;
		margin-top: -30px;
	}

	.cal_instructions_button {
		font-size: 14px !important;
		padding: 0 2px !important;
	}

	.edit_service_button {
		padding: 0 2px !important;
	}

	.cambridge-btn,
	.milton-btn {
		font-size: 50px;
		padding: 40px 8px;
		display: block;
		width: 95%;
		margin: 10px auto;
	}

	.instructions_container {
		position: absolute;
		left: 0;
		right: 0;
		margin-left: auto;
		margin-right: auto;
		z-index: 100;
		height: 60%;
		background-color: rgba(219, 219, 189, 0.918);
		overflow: scroll;
		padding: 5px;
		width: 95%;
		border: 1px solid rgb(228, 233, 236);
		border-radius: 3px;
	}

	.cityChosen {
		font-size: 18px;
	}
	.pet_selection_service_groomer_container {
		width: 100%;
		padding: 5px;
	}

	.calendar_instruction_hide {
		display: none;
	}
}

@media only screen and (min-device-width: 100px) and (max-width: 991px) {
	.groomers-profile-pics__wrapper {
		width: 100%;
	}

	.nail-trim-service {
		width: 98%;
	}
}

@media only screen and (min-device-width: 220px) and (max-width: 580px) {
	.nail-trim-service {
		width: 98%;
		font-size: 15px;
	}

	.groomers-profile-pics {
		width: 80%;
	}

	.groomers-profile-pics__calendar {
		width: 20%;
		border: 1px solid rgb(32, 110, 184);
	}

	.choose-title {
		font-size: 24px;
		margin-bottom: 30px;
	}

	.schedule-primary-heading__petType {
		font-size: 18px;
		margin-top: 20px;
	}

	.schedule-primary-heading__availableTimes {
		font-size: 14px;
	}
}
