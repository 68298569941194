.loading-status-full-page {
	background: rgba(12, 61, 96, 0.801);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	z-index: 10000;
}

.loading-spinner {
	font-size: 40px;
	color: white;
	margin-right: auto;
	margin-left: auto;
	display: block;
	width: 20%;
	padding-top: 20%;
	font-weight: bolder;
}
