.cal-main-container {
	max-height: 100vh;
	overflow: auto;
}

.calendar_main_title {
	text-align: center;
	padding: 10px;
	color: rgb(14, 77, 97);
	background-color: #eaf1f0;
}

.event-history-wrapper {
	background-color: #020a16d5;
	color: white;
	margin: 0px;
	padding-left: 5px;
	border: 1px white solid;
	border-radius: 5px;
	text-align: center;
}

.borderError {
	border: 1px solid red !important;
}

.circleIconDog {
	-webkit-text-fill-color: rgb(255, 255, 255);
	-webkit-text-stroke-color: rgb(0, 69, 197);
	-webkit-text-stroke-width: 1px;
}

.circleIconBath {
	-webkit-text-fill-color: rgb(255, 255, 255);
	-webkit-text-stroke-color: rgb(0, 197, 171);
	-webkit-text-stroke-width: 1px;
}

.circleIconCat {
	-webkit-text-fill-color: rgb(255, 255, 255);
	-webkit-text-stroke-color: rgb(238, 107, 172);
	-webkit-text-stroke-width: 1px;
}
.circleIconInternal {
	-webkit-text-fill-color: rgb(255, 255, 255);
	-webkit-text-stroke-color: rgb(107, 238, 111);
	-webkit-text-stroke-width: 1px;
}

.options_smallDogGrooming-request {
	-webkit-text-fill-color: rgb(132, 183, 250);
	-webkit-text-stroke-color: rgb(255, 199, 15);
	-webkit-text-stroke-width: 0.5px;
	font-size: 14px;
}

.options_mediumDogGrooming-request {
	-webkit-text-fill-color: rgb(46, 105, 182);
	-webkit-text-stroke-color: rgb(255, 199, 15);
	-webkit-text-stroke-width: 0.5px;
	font-size: 16px;
}

.options_bigDogGrooming-request {
	-webkit-text-fill-color: rgb(5, 42, 90);
	-webkit-text-stroke-color: rgb(255, 199, 15);
	-webkit-text-stroke-width: 0.5px;
	font-size: 18px;
}

.options_smallDog {
	-webkit-text-fill-color: rgb(132, 183, 250);
	-webkit-text-stroke-color: rgb(121, 235, 255);
	-webkit-text-stroke-width: 0.5px;
	font-size: 14px;
}

.options_mediumDog {
	-webkit-text-fill-color: rgb(46, 105, 182);
	-webkit-text-stroke-color: rgb(121, 235, 255);
	-webkit-text-stroke-width: 0.5px;
	font-size: 16px;
}

.options_bigDog {
	-webkit-text-fill-color: rgb(5, 42, 90);
	-webkit-text-stroke-color: rgb(121, 235, 255);
	-webkit-text-stroke-width: 0.5px;
	font-size: 18px;
	font-weight: 700;
}

.options_smallDogGrooming {
	-webkit-text-fill-color: rgb(117, 186, 250);
	-webkit-text-stroke-width: 0.5px;
	font-size: 14px;
}

.options_mediumDogGrooming {
	-webkit-text-fill-color: rgb(46, 105, 182);
	-webkit-text-stroke-width: 0.5px;
	font-size: 16px;
}

.options_bigDogGrooming {
	-webkit-text-fill-color: rgb(5, 42, 90);
	/* -webkit-text-stroke-color: rgb(121, 235, 255); */
	-webkit-text-stroke-width: 0.5px;
	font-size: 18px;
	font-weight: 700;
}

.calendars-wrapper {
	margin-left: 0;
	background-color: #fff;
	-webkit-filter: drop-shadow(0px 15px 15px #e4e4e4);
	filter: drop-shadow(0px 15px 15px #d8d7d7);
}

.input-search-appointments {
	width: 90%;
	margin: 10px auto 0 auto;
	display: block;
	padding: 10px;
	border: 1px solid rgb(177, 177, 177) !important;
	color: rgb(0, 0, 0);
	border-radius: 5px;
}

.time-on-modal {
	margin: 15px auto;
	text-align: center;
}

.input-search-appointments-btn {
	outline: 0;
	margin: 10px auto;
	padding: 6px 20px;
	display: block;
	text-align: center;
	border-radius: 5px;
	width: 90%;
	color: white;
	border: none;
	background-color: rgb(221, 207, 14);
	font-size: 18px;
	font-weight: 800;
	text-shadow: 1px 1px rgb(112, 31, 31);
	-webkit-filter: drop-shadow(0px 5px 5px #838383);
	filter: drop-shadow(0px 5px 5px #7c7c7c);
}

.searchContainer-appointments {
	width: 95%;
	position: absolute;
	top: 50px;
	z-index: 10;
	background: rgb(255, 255, 255);
	border: 1px solid rgb(177, 177, 177) !important;
	-webkit-filter: drop-shadow(0px 15px 15px #646464);
	filter: drop-shadow(0px 15px 15px #646464);
}

.appointment-type-select {
	text-align: center;
}

.searchAppointmentsButton,
.searchAppointmentsButton:active {
	outline: 0;
	background: rgb(14, 77, 97);
	font-size: 20px;
	margin: 10px auto 0 auto;
	padding: 5px 20px;
	float: right;
	border: none;
	width: 100%;
	border-radius: 5px;
	color: rgb(255, 255, 255);
	text-shadow: 1px 1px rgb(112, 31, 31);
}

.searchAppointmentsButton:focus {
	background: rgb(14, 147, 180);
	outline: 0;
}

@media (max-width: 768px) {
	/* Hide resize handles on mobile */
	.rbc-event-resizer {
		display: none;
	}
}
