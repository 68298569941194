table,
th,
td {
	border: 1px solid black;
}

.button-special-calc {
	margin: 10px;
}

.remove-link {
	cursor: pointer;
}

main {
	display: block; /* new */
	width: 200px;
}

.adminPageContainer {
	min-height: 100vh; /* will cover the 100% of viewport */
	overflow: hidden;
	display: block;
	position: relative;
	padding-bottom: 60px; /* height of your footer */
}

.clear-commission-wrapper {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.admin-panel-sec-container {
	background: rgb(255, 255, 255);
	padding: 20px;
	margin: 10px;
	text-align: center;
}

#lastName,
#firstName,
#phone,
#petName,
#breed,
#notes {
	float: right;
}

.formBox {
	border: 1px solid;
	color: white;
	background: rgba(56, 76, 100, 0.897);
	padding: 15px;
}

.update-employee-btn {
	margin-top: 20px;
	display: inline-block;
	width: 100%;
	border-radius: 10px;
	background-color: teal;
	color: white;
	padding: 5px;
	text-transform: uppercase;
	font-weight: 700;
}

.clientsTable {
	overflow-x: auto;
	overflow: scroll;
}

.signOutLink {
	display: none;
}

.welcomeAdminMessage {
	text-align: center;
	text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
}

.buttons-control-panel-admin {
	font-size: 16px;
	margin-bottom: 15px;
	color: black;
	font-weight: 700;
	border-radius: 10px;
	transition: all 0.2s;
	box-shadow: 0px 10px 10px rgb(189, 188, 188);
	padding: 5px 15px;
	outline: 0;

	&:hover {
		transform: scale(1.1);
		background-color: rgb(48, 58, 87);
		color: white;
	}

	&__receptionist {
		background-color: rgb(0, 255, 255);
	}

	&__employees {
		background-color: rgb(202, 241, 160);
	}

	&__clientRegistration {
		background-color: rgb(200, 200, 200);
	}

	&__commissionReceptionist {
		background-color: rgb(178, 178, 238);
	}

	&__commissionAdmin {
		background-color: rgb(252, 217, 173);
	}

	&__cambridgeClients {
		background-color: rgb(189, 93, 93);
		color: white;
	}
}

.buttonsControlPanelManageEmployees {
	margin-left: 15px;
	border-radius: 10px;
}

.commissions-container {
	background-color: rgb(255, 255, 255);
	height: 100%;
	padding: 10px;
	filter: drop-shadow(10px 10px 4px #7c7c7c);
}

.right-calculations {
	background-color: rgb(236, 243, 243);
	padding: 10px;
	border-radius: 10px;
	height: 100%;
	overflow: visible;
	filter: drop-shadow(10px 10px 4px #a5a4a4);
}

.left-commissions {
	background-color: rgb(28, 40, 41);
	padding: 10px;
	border-radius: 10px;
	margin-top: 10px;
	color: white;
}

.result-claudia-container {
	padding: 0 10px;
}

.calculation-subtitle {
	padding-top: 10px;
}

.pets-list-claudia-container {
	max-height: 140px;
	overflow: auto;
}

.special-commission-buttons-wrapper {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: center;
}

.search-commission-form {
	display: flex;
	align-items: center;
	justify-content: space-around;
	flex-wrap: wrap;
}

.input-label-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border: 1px solid rgb(228, 228, 228);
	height: 40px;
}

.input-label-wrapper input {
	border: 1px solid rgb(214, 214, 214);
	outline: none;
	border-radius: 3px;
	width: 60%;
	height: 100%;
	padding-left: 5px;
}

.admin-panel-btns-container {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: space-around;
}
.employee-btn,
.generate-report-btn,
.btn-calculate-actions {
	margin-left: 5px;
}
.card {
	box-shadow: 5px 5px 10px rgb(221, 221, 221);
}
.salarySearchResults-container {
	text-align: center;
}

.edit-btn-salary {
	cursor: pointer;
	border-radius: 3px;
	height: 100%;
	padding-top: 10px;
	padding-right: 5px;

	&:hover {
		color: rgb(36, 41, 65) !important;
		text-decoration: underline;
	}
}

.search-commission-btn {
	background-color: rgb(255, 2, 221) !important;
	font-weight: 700 !important;
	color: aliceblue !important;

	&:hover {
		filter: brightness(2);
	}
}

.search-result-info-wrapper {
	display: grid;
	text-align: left;

	span {
		width: 100%;
	}
}

.eye_icon {
	position: absolute;
	right: 20px;
	top: 20px;
}

.result-wrapper {
	width: 30%;
	display: flex;
	justify-content: space-between;
	margin: 0 auto;
	border: 1px solid rgb(241, 241, 241);
	align-items: center;
	border-radius: 3px;
}
@media (max-width: 1578px) {
	.btns-wrapper-calculation {
		display: flex;
		margin-top: 10px;
		flex-wrap: wrap;
	}
	.result-wrapper {
		width: 60%;
	}
}
@media (max-width: 900px) {
	.result-wrapper {
		width: 100%;
	}
	.btns-wrapper-calculation {
		justify-content: center;
	}
	.employee-btn,
	.generate-report-btn {
		display: block;
		width: 30%;
		margin-top: 10px;
	}
}
@media (max-width: 550px) {
	.special-commission-buttons-wrapper {
		display: grid;
	}
	div.special-commission-buttons-wrapper > div:nth-child(1) > div > input,
	div.special-commission-buttons-wrapper > div:nth-child(2) > div > input {
		width: 100%;
		margin: 4px 0;
	}
	.btn-calculate-actions {
		margin-left: 0;
	}
	.btns-wrapper-calculation {
		margin-top: 0;
	}
	.report-generated-container {
		display: block !important;
		padding: 5px;
	}
	.admin-panel-btns-container .btn {
		margin-top: 5px;
	}
	.admin-panel-btns-container {
		justify-content: space-between;
	}
}
@media (max-width: 480px) {
	.admin-panel-btns-container .btn {
		width: 100%;
	}
	.employee-btn,
	.generate-report-btn {
		width: 100%;
		margin-top: 4px;
		margin-left: 0px !important;
	}
}

@media (max-width: 360px) {
	// .signOutLink {
	// 	display: block;
	// }

	.welcomeAdminMessage {
		margin-top: 45px;
	}

	.buttonsControlPanelManageEmployees {
		font-size: 17px;
		width: 100%;
		display: block;
		margin-left: 0px;
	}
}
