.temporarily-span {
	text-decoration: underline;
	color: red;
}

.covid-19-poster {
	width: 100%;
	margin-top: 20px;
}

.cambridge_animated {
	text-shadow: 2px 2px #0059ff;
	display: inline-block;
	/* Start the shake animation and make the animation last for 0.5 seconds */
	animation: animateShake 2s ease infinite;
	transform-origin: 50% 50%;
}

@keyframes animateShake {
	0% {
		transform: translate(0, 0);
	}
	1.78571% {
		transform: translate(0, 5px);
	}
	3.57143% {
		transform: translate(0, 0);
	}
	5.35714% {
		transform: translate(0, 5px);
	}
	7.14286% {
		transform: translate(0, 0);
	}
	8.92857% {
		transform: translate(0, 5px);
	}
	10.71429% {
		transform: translate(0, 0);
	}
	100% {
		transform: translate(0, 0);
	}
}

.temporarySign-container,
.temporarySign-container a {
	font-family: Georgia, "Times New Roman", Times, serif;
	text-align: center;
	background: rgb(255, 255, 255);
	color: rgb(0, 0, 0);
	padding: 20px;
	border-radius: 5px;
	border: 2px solid white;
	width: 100%;
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.facebook-link-temp {
	color: blue;
}

.closeButton-tempSign {
	float: right;
	background: rgb(204, 206, 73);
	color: rgb(17, 0, 0);
	padding: 0 3px;
	border-radius: 5px;
	transition: all 0.2s;
}

.closeButton-tempSign:hover {
	cursor: pointer;
	background: rgb(148, 150, 46);
}

.logoTemporarySign {
	width: 100px;
	left: 0px;
}

.canada-flag {
	width: 40px;
	height: 30px;
	margin-left: 15px;
}

@media only screen and (min-device-width: 300px) and (max-device-width: 420px) {
	.covid-19-title {
		font-size: 16px;
		font-weight: 700;
	}

	.closeButton-tempSign {
		font-size: 10px;
	}

	.break-covid-poster {
		display: none;
	}

	.covid-19-poster-paragraph {
		text-align: center;
		font-size: 12px;
		margin-top: 30px;
	}
}
