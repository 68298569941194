.customer-add-pet-page-container__cambridge {
	background-color: rgba(192, 221, 238, 0.075);
	// padding: 0 10px;
	border-radius: 5px;
	border: solid 1px rgb(219, 219, 219);
	color: rgb(63, 63, 63);
	// margin: 80px auto;
	// position: absolute;
	width: 100%;
	// overflow: scroll;
	// box-shadow: 0 2px 20px rgb(170, 168, 168);
}

.toolbar_logo_simple {
	font-size: 22px;
	color: yellow;
}

.register_pet_container {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	column-gap: 10px;
}

.register_pet_client_container {
	grid-column-start: 1;
	grid-column-end: 3;
	color: rgb(63, 63, 63);
	padding: 10px;
}

.registration_pet_pet_wrapper {
	grid-column: 3;
}

.type_select {
	width: 100%;
	border-radius: 3px;
	border: solid rgb(201, 198, 198) 1px;
	padding: 5px;
}

.pets_list_title {
	display: none;
}

@media only screen and (min-device-width: 220px) and (max-width: 1024px) {
	.customer-add-pet-page-container__cambridge {
		width: 98%;
	}
	.register_pet_container {
		grid-template-columns: 1fr;
	}
	.register_pet_client_container {
		grid-column: 1;
	}
	.registration_pet_pet_wrapper {
		grid-column: 1;
	}
	.pets_list_title {
		display: block;
	}
}
