.waitList-admin-container {
  padding: 20px;
  background-color: #fff;
}

.waitList-city-heading {
  width: 20%;
  padding: 10px;
  border-radius: 5px;
  margin: 10px auto;
  color: white;
  &__milton {
    background-color: rgb(235, 44, 203);
  }

  &__cambridge {
    background-color: rgb(45, 175, 136);
  }
}

.btn-city-waiting-list {
  margin-left: 10px;
  padding: 5px 15px;
  font-size: 16px;
  border-radius: 5px;
  font-weight: 700;
  width: 10%;
  color: white;
  box-shadow: 0px 5px 20px rgb(22, 22, 17);
  border: 1px solid rgb(115, 130, 196);
  transition: all 0.2s;

  &:hover {
    transform: scale(1.05);
  }

  &__milton {
    background-color: rgb(235, 44, 203);
  }

  &__cambridge {
    background-color: rgb(45, 175, 136);
  }
}

@media only screen and (min-device-width: 200px) and (max-device-width: 991px) {
  .btn-city-waiting-list {
    width: 40%;
  }

  .waitList-city-heading {
    width: 100%;
  }
}
