.signinPage {
	/* height: 100vh; */
	margin: 0 auto;
	width: 100%;
	margin-bottom: 0;
}

.form-box-signin {
	border: 1px solid white;
	border-radius: 10px;
	background: rgba(0, 0, 0, 0.979);
	opacity: 0.9;
	color: white;
	// margin-bottom: 30px;
	margin-top: 100px;
	padding: 30px;
	height: 100%;
}

.form-signin {
	margin-bottom: 10px;
}

.logo-signin-form {
	width: 30%;
	margin-top: 7px;
	margin-left: 35%;
	margin-right: 35%;
}

.signin-form-err-msg {
	text-align: center;
	color: yellow;
	padding-bottom: 10px;
	margin-top: 15px;
}

.signin-title {
	text-align: center;
	margin-top: 15px;
}
