.waiting-list-container {
	padding: 20px;
	width: 100%;
	border-radius: 10px;
	border: 2px solid white;
}

.waitList-form-container {
	padding: 20px;
	border-radius: 5px;
	-webkit-filter: drop-shadow(0px 10px 10px #000000);
	filter: drop-shadow(0px 10px 10px #000000);
}
.waitList-form-container__cambridge {
	background-color: rgb(45, 175, 136);
}
.waitList-form-container__milton {
	background-color: rgb(235, 44, 203);
}

.errMsg-waitList-form {
	margin-top: 20px;
	color: red;
	background-color: rgba(255, 255, 255, 0.87);
	width: 80%;
	margin: 15px auto;
	border-radius: 5px;
}

.waitList-btns-container {
	display: flex;
	justify-content: space-around;
	// margin-top: 30px;
}

.link-btn-waitList {
	width: 100%;
}

.putMeOnWaitList-btn {
	font-size: 26px;
	margin: 0 auto;
	padding: 10px 25px;
	border-radius: 5px;
	font-weight: 700;
	background-color: rgb(33, 46, 48);
	color: white;
	box-shadow: 0px 5px 20px rgb(22, 22, 17);
}

.city-btns-waitList {
	margin: 0 auto;
	padding: 10px 25px;
	font-size: 22px;
	border-radius: 5px;
	font-weight: 700;
	width: 98%;
	display: block;
	color: white;
	box-shadow: 0px 5px 20px rgb(22, 22, 17);
	border: 1px solid rgb(115, 130, 196);
	transition: all 0.2s;

	&:hover {
		transform: scale(1.05);
	}

	&__milton {
		background-color: rgb(235, 44, 203);
	}

	&__cambridge {
		background-color: rgb(45, 175, 136);
	}
}

.heading-waitingList {
	text-align: center;
	font-size: 20px;

	&_secondary {
		text-align: center;
		font-size: 24px;
		font-weight: 700;
	}
}

.dog-surprised-img {
	width: 20%;
}

@media only screen and (min-device-width: 500px) and (max-device-width: 991px) {
	.heading-waitingList {
		text-align: center;
		font-size: 20px;
	}

	.dog-surprised-img {
		width: 20%;
	}

	.waiting-list-container {
		margin-top: 70px;
	}

	.putMeOnWaitList-btn {
		font-size: 20px;
	}

	.waiting-list-container {
		width: 85%;
	}
}

@media only screen and (min-device-width: 200px) and (max-device-width: 499px) {
	.heading-waitingList {
		font-size: 20px;
	}

	.dog-surprised-img {
		width: 40%;
	}

	.waiting-list-container {
		margin-top: 70px;
	}

	.putMeOnWaitList-btn {
		font-size: 20px;
	}

	.waiting-list-container {
		width: 95%;
	}
}
