.table-container {
	overflow-x: auto; /* This enables horizontal scrolling */
	width: 100%;
}

.table-custom {
	border-collapse: collapse;
	border-spacing: 0;
	width: 100%;
	border: 1px solid #ddd;
}

.table-custom th,
.table-custom td {
	text-align: left;
	padding: 8px;
	border: 1px solid #ddd;
}

.table-custom tr:nth-child(even) {
	background-color: #f2f2f2;
}

.table-custom tr:hover {
	background-color: #ddd;
}

.table-custom th {
	padding-top: 6px;
	padding-bottom: 6px;
	text-align: center;
	background-color: #81adc6;
	color: white;
}
