@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	@font-face {
		font-family: "Eigerdals";
		font-weight: 400;
		src: url("../public/fonts/Eigerdals-Reg.woff2") format("woff2"),
			url("../public/fonts/Eigerdals-Reg.woff") format("woff");
	}
	@font-face {
		font-family: "Eigerdals";
		font-weight: 500;
		src: url("../public/fonts/Eigerdals-Med.woff2") format("woff2"),
			url("../public/fonts/Eigerdals-Med.woff") format("woff");
	}
	@font-face {
		font-family: "Eigerdals";
		font-weight: 700;
		src: url("../public/fonts/Eigerdals-Bol.woff2") format("woff2"),
			url("../public/fonts/Eigerdals-Bol.woff") format("woff");
	}
	@font-face {
		font-family: "Eigerdals";
		font-weight: 900;
		src: url("../public/fonts/Eigerdals-Bla.woff2") format("woff2"),
			url("../public/fonts/Eigerdals-Bla.woff") format("woff");
	}
	@font-face {
		font-family: "Eigerdals";
		font-style: italic;
		src: url("../public/fonts/Eigerdals-RegIta.woff2") format("woff2"),
			url("../public/fonts/Eigerdals-RegIta.woff2") format("woff");
	}
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Eigerdals", sans-serif;
}

html {
	scroll-behavior: smooth;
}

.lineParentDividerMain,
.lineParentDividerText,
.lineParent,
.lineParentDividerListItem,
.lineReviewParent,
.lineParentAboutTitle,
.lineParentMeetTeamTitle,
.lineParentContactTitle,
.lineParentGalleryTitle,
.lineParentsServicesTitle {
	overflow: hidden;
}

@media (max-width: 767px) {
	.footer_custom {
		margin-top: 220px;
	}
}
