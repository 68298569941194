.toolbar {
	position: fixed;
	z-index: 99;
	top: 0px;
	top: 0;
	left: 0;
	width: 100%;
	background: rgba(95, 188, 250, 0.952);
	height: 7%;
	text-shadow: 1px 1px rgb(0, 0, 2);
	border-bottom: 1px;
	font-family: Georgia, "Times New Roman", Times, serif;
}

.book-appointment-navbar {
	background-color: rgb(243, 229, 28);
	padding: 5px;
	border-radius: 5px;
	color: rgb(61, 61, 61) !important;
}

.usernameLoggedinHome {
	color: rgb(0, 0, 0);
	font-size: 18px;
}

.nav-items-no-loggedin {
	color: rgb(0, 0, 0);
	margin-left: 10px;
	border-left: rgb(3, 0, 0) solid 1px;
	padding-left: 10px;
	text-align: center;
}

.controlPanelNavbar {
	color: rgb(2, 2, 19);
	background: rgb(8, 150, 150);
	border: 1px white solid;
	border-radius: 5px;
	padding: 5px;
	margin-left: 15px;
	transition: all 0.1s;
	backface-visibility: hidden;
}

.commissionBtnControlPanel {
	background: rgb(247, 112, 157);
	margin-bottom: 15px;
	margin-left: 5px;
	border: solid 2px black;
	padding: 10px;
	font-size: 19px;
	font-weight: 700;
	border-radius: 10px;
}

.commissionBtnControlPanel:hover {
	transform: scale(1.1);
}

.controlPanelNavbar:hover {
	transform: scale(1.1);
}

.logoutBtnNavBar {
	color: navy;
	background: rgb(255, 230, 0);
	border: 1px white solid;
	border-radius: 5px;
	padding: 5px;
	margin-left: 20px;
	transition: all 0.1s;
	backface-visibility: hidden;
}

.logoutBtnNavBar:hover {
	cursor: pointer;
	transform: scale(1.1);
	backface-visibility: hidden;
}

.commissionPage-btn {
	padding: 5px;
	transition: all 0.1s;
	&:hover {
		transform: scale(1.1);
	}
}

.adminBtnNavbar {
	color: rgb(0, 0, 0);
	font-size: 18px !important;
}

.adminPanelBtnNavbar:hover {
	transform: scale(1.1);
}

.adminPanelBtnNavbar {
	border-radius: 5px;
	background: rgb(26, 22, 22);
	margin-left: 15px;
	padding: 5px;
	transition: all 0.2s;
}

.toolbar__navigation {
	display: flex;
	height: 100%;
	align-items: center;
	padding: 0 1rem;
}

.toolbar__logo {
	margin-left: 1rem;
	transition: all 0.2s;
	font-size: 24px;
	color: rgb(235, 227, 128) !important;
	text-decoration: none !important;
	cursor: pointer;
}

.toolbar__logo:hover {
	transform: scale(1.1);
	backface-visibility: hidden;
}

.toolbar__logo a {
	color: rgb(235, 227, 128);
	text-decoration: none;
	font-size: 1.5rem;
}

.spacer {
	flex: 1;
}

.toolbar_navigation-items ul {
	list-style: none;
	margin: 0;
	padding: 0;
	display: flex;
}

.toolbar_logo_simple {
	text-decoration: none !important;
	color: yellow;

	&:hover {
		color: rgb(255, 255, 255);
	}
}

.toolbar_navigation-items a {
	text-decoration: none !important;
	font-size: 18px;
	transition: all 0.2s;
	&:active {
		color: rgb(240, 200, 25) !important;
	}
}
.toolbar_navigation-items ul li span {
	font-size: 18px;
	transition: all 0.2s;
	cursor: pointer;
	color: white;
	/* display: inline-block; */
}

.nav_times_wrapper li a:active {
	color: rgb(25, 240, 107);
}

.toolbar_navigation-items a:hover,
.toolbar_navigation-items a:active {
	color: rgb(240, 200, 25) !important;
	/* transform: scale(1.1); */
}
.toolbar_navigation-items ul li span:hover {
	color: rgb(240, 200, 25) !important;
	/* transform: scale(1.1); */
}

@media only screen and (min-device-width: 20px) and (max-device-width: 767px) {
	.homeIcon {
		display: none;
	}

	.toolbar__logo {
		font-size: 18px;
	}

	.toolbar__logo a {
		font-size: 120%;
	}
}

@media (max-width: 943px) {
	.toolbar_navigation-items {
		display: none;
	}
	.toolbar__logo {
		font-size: 16px;
	}
}

@media only screen and (min-device-width: 944px) and (max-device-width: 1169px) {
	.toolbar__toggle-button {
		display: none;
	}
	.toolbar__logo {
		margin-left: 0;
		margin-top: 1px;
		font-size: 14px;
	}

	.amazingpetgrooming-navbar,
	.pawIconToolBar {
		display: none;
	}
}

@media only screen and (min-device-width: 1170px) and (max-device-width: 3199px) {
	.toolbar__toggle-button {
		display: none;
	}
}
