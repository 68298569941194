.customer-add-pet-page-container {
	background-color: rgba(20, 50, 75, 0.753);
	padding: 40px;
	border-radius: 5px;
	color: white;
}

.customer-info-container {
	color: black;
	padding: 20px;
}

.fa-camera-retro {
	font-size: 25px;
}

.pet-card-customer {
	color: black;
	padding: 10px;
	margin-top: 5px;
	border-radius: 5px;
}

.pet-info-customer-heading {
	padding: 10px;
}

.label-customer-addPet-page {
	font-size: 20px;
}

.text-content-addPet-page {
	font-weight: 800;
	font-size: 22px;
	color: rgb(13, 12, 71);
}

.text-content-addPet-page-pet {
	font-weight: 700;
	font-size: 20px;
	color: rgb(13, 12, 71);
}

.petInfo-container {
	padding: 5px;
}

.pet-pic-img-customer {
	width: 70%;
}

.add-pet-button-customer {
	margin-top: 10px;
	padding: 10px;
	font-size: 27px;
}

.done-btn {
	margin-top: 5px;
	padding: 10px;
	font-size: 27px;
	background-color: rgb(199, 255, 86);
	border: 4px black solid;
	color: black;
}

.closeButton-finish {
	z-index: 1000;
	position: absolute;
	top: 200px;
}

.count-down-bar {
	margin-top: 30px;
	height: 400px;
	background-color: rgba(153, 205, 50, 0.288);
	padding: 20px;
	color: white;
	font-size: 27px;
	font-weight: bolder;
	position: relative;
	z-index: 2;
	backface-visibility: hidden;

	&_heading {
		transition: all 10s infinite;
		opacity: 1;
		color: rgb(2, 7, 68);
		font-size: 40px;
		font-weight: 800;
		text-shadow: 3px 3px rgba(20, 50, 75, 0.753);
		animation: textMovingDown 10s;
	}
}

.agree-with-photo {
	margin-top: 10px;
	background-color: rgb(88, 103, 117);
	padding: 10px 20px;
	color: rgb(255, 255, 255);
}

.bg-video__content-pet {
	width: 100%;
	height: 100%;
	object-fit: cover;
	overflow: hidden;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	opacity: 0.6;
}

@keyframes textMovingDown {
	0% {
		transform: translateY(-50px) scale(0.5);
	}

	100% {
		transform: translateY(280px) scale(1);
	}
}
