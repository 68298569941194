.side-drawer {
	height: 100%;
	background: rgba(4, 74, 107, 0.89);
	box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
	position: fixed;
	top: 0;
	left: 0;
	width: 70%;
	max-width: 400px;
	z-index: 200;
	transform: translateX(-100%);
	transition: transform 0.3s ease-out;
	padding: 0 20px;
}

.controlPanelSidebar {
	font-size: 16px !important;
	color: rgb(2, 2, 19) !important;
	background: rgb(197, 233, 233);
	border: 1px white solid;
	border-radius: 5px;
	padding: 0 5px 0 5px;
	width: 60px !important;
}

.adminBtnSidebar {
	color: rgb(0, 0, 0);
}

.adminPanelBtnSidebar {
	border-radius: 5px;
	font-size: 18px;
	border-radius: 5px;
	margin: 5px 0px 15px 0px;
	width: 60%;
	background-color: rgb(34, 39, 16);
}

.mainDeskPanelBtnSidebar {
	border-radius: 5px;
	font-size: 18px;
	border-radius: 5px;
	margin: 0px 0px 15px 0px;
	width: 60%;
	background-color: rgb(1, 238, 255);
	padding: 5px 10px;
}

.logoutBtnSidebar {
	border-radius: 5px;
	font-size: 18px;
	border-radius: 5px;
	margin: 0 0 15px 0;
	width: 60%;
	background-color: rgb(255, 251, 1);
}

.usernameLoggedinHomeSidebar {
	color: rgb(251, 255, 0);
	font-size: 18px !important;
}

.side-drawer.open {
	transform: translateX(0);
}

.side-drawer ul {
	height: 100%;
	list-style: none;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.side-drawer li {
	margin: 0.5rem 0;
}

.side-drawer a,
.side-drawer span {
	color: rgb(248, 248, 250);
	text-decoration: none;
	font-size: 1.2rem;
}

.side-drawer a:hover,
.side-drawer a:active {
	color: rgb(30, 30, 150);
}

@media (min-width: 769px) {
	.side-drawer {
		display: none;
	}
}
