@mixin margin-top-big {
	margin-top: 70px;
}

@mixin margin-top-medium {
	margin-top: 40px;
}

@mixin margin-top-small {
	margin-top: 20px;
}

.margin-top-big {
	@include margin-top-big();
}

.margin-top-medium {
	@include margin-top-medium();
}

.margin-top-small {
	@include margin-top-small();
}

.commission-parent-container {
	background-color: rgb(255, 255, 255);
	min-height: 100vh;
	width: 100%;
	margin: 0;
	padding-bottom: 60px;
}

.addPet-btn {
	margin-top: 10px;
}

.update-info-commission {
	background-color: rgb(0, 0, 0);
	color: white;
	border-radius: 3px;
	display: block;
	width: 100%;
	box-shadow: 0px 10px 20px black;
	transition: all 0.2s;

	&:hover {
		transform: scale(1.1);
		background-color: rgb(2, 27, 26);
	}
}

.time-content {
	background-color: rgb(15, 31, 75);
	padding: 5px;
	color: white;
	border-radius: 5px;
}

.form-commission {
	margin-top: 10px;
}

.form-edit-commission {
	background-color: rgb(214, 229, 231);
	padding: 10px;
}

.commission-btn {
	width: 100%;
	font-size: 17px;
	font-weight: 600;
	text-transform: uppercase;
	color: black;
	font-weight: 700;
	padding: 5px 15px;
	border-radius: 5px;
	border: grey solid 1px;
	transition: all 0.3s;

	&:hover {
		background-color: rgb(236, 228, 116);
	}

	&_daycare {
		background-color: rgb(39, 5, 112);
		color: white;
	}
	&_claudia {
		background-color: rgb(176, 213, 250);
	}
	&_diana {
		background-color: rgb(241, 219, 240);
	}
	&_paola {
		background-color: rgb(255, 255, 255);
	}
	&_groomer {
		background-color: rgb(250, 249, 186);
	}
	&_groomer2 {
		background-color: rgb(195, 252, 202);
	}
	//Adriana
	&_adriana {
		background-color: rgb(219, 118, 122);
	}
	//Mercedes
	&_mercedes {
		background-color: rgb(252, 248, 229);
	}
	//Peppa
	&_groomer4 {
		background-color: rgb(212, 212, 211);
	}
}

.tdata-commission {
	&_time {
		width: 10%;
	}
}

.btn-phone-icon-commission {
	width: 30px;
	border: 1px grey solid;
	border-radius: 3px;
	box-shadow: 0px 10px 25px black;
	padding-top: 5px;
	transition: all 0.1s;
	&:hover {
		transform: scale(1.1);
		box-shadow: 0px 10px 25px rgb(51, 187, 57);
	}
}

.phone-icon-commission {
	font-size: 18px;
	text-align: center;
	backface-visibility: hidden;
}

.commission-groomers-info {
	padding: 10px;
	margin-top: 20px;
	height: 100%;
	color: white;
	display: block;
	border: 1px solid rgb(201, 201, 201);

	&_daycare {
		background-color: rgb(249, 199, 142);
	}
	&_claudia {
		background-color: rgb(176, 213, 250);
	}

	&_diana {
		background-color: rgb(238, 218, 231);
	}
	&_paola {
		background-color: rgb(255, 255, 255);
	}
	//Frank
	&_groomer {
		background-color: rgb(202, 201, 142);
	}
	//Baths
	&_groomer2 {
		background-color: rgb(207, 230, 210);
	}
	//Adriana
	&_adriana {
		background-color: rgb(219, 118, 122);
	}
	//Mercedes
	&_mercedes {
		background-color: rgb(252, 248, 229);
	}
	//Peppa
	&_groomer4 {
		background-color: rgb(212, 212, 211);
	}
}

.commission-subheading {
	display: block;
	width: 100%;
	padding-top: 10px;
	color: black;
	font-weight: 600;
	text-transform: uppercase;
}

.theading-commission {
	text-align: center;
	text-transform: uppercase;
}

@media only screen and (min-device-width: 200px) and (max-width: 599px) {
	.commission-parent-container {
		margin-top: 50px;
	}
}
